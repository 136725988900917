.body {
    font-family: 'EB Garamond', serif;
    text-align: left;
    font-size: 125%;
    width: 60%;
    margin: auto;
    line-height: 1.5;
}

.title {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    padding-top: 1.5rem;
    text-align: left;
    width: 60%;
    margin: auto;
}

.date {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    text-align: left;
    width: 60%;
    margin: auto;
}