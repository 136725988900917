@media screen and (min-width: 1000px) {
  .NavItem {
    font-family: 'Playfair Display', serif;
    text-decoration: none;
    font-size: 1.5rem;
    text-align: left;
    padding-top: 1rem;
  }

  .NavItem:hover {
    text-decoration: underline;
  }

  a {
    color: #292929;
    text-decoration: none;
  }
}

@media screen and (max-width: 999px) {
  .NavItem {
    display: inline;
    font-family: 'EB Garamond', serif;
    font-size: 1.1rem;
  }
}
