@media screen and (min-width: 1000px) {
  .TitleName {
    font-family: 'EB Garamond', serif;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    letter-spacing: 2px;
    padding-left: 2rem;
    padding-top: 2rem;
  }

  .Container {
    float: left;
    padding-right: 1rem;
    max-width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 999px) {
  .TitleName {
    font-family: 'EB Garamond', serif;
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 1px;
    padding-left: 1rem;
    margin-right: 1rem;
  }

  .Container {
    display: flex;
    max-width: 90%;
    margin: auto;
  }
}

.LinkName {
  text-decoration: none;
}

.LinkName:hover {
  font-style: italic;
}
