@media screen and (min-width: 500px) {
    .Link {
        text-decoration: none;
        padding: 2em;
    }
    
    a:visited {
        color: #292929;
    }
    
    .title {
        font-family: 'Playfair Display', serif;
        font-size: 2.5rem;
        text-align: left;
        color: #292929;
        transition: all .15s ease-in-out;
        transform-origin: left;
    }
    
    .title:hover {
        text-decoration: underline;
        transform: scale(1.04);
    }
    
    .description {
        font-family: 'EB Garamond', serif;
        text-align: left;
        font-size: 125%;
        padding-bottom: .75rem;
        color: #292929;
    }
    
    .date {
        font-family: 'Playfair Display', serif;
        text-align: left;
        font-size: 100%;
        color: #292929;
        font-style: italic;
    }

    .arrow {
        padding-left: 1rem;
    }
}



@media screen and (max-width: 499px) {
    .Link {
        text-decoration: none;
        padding: 2em;
    }
    
    a:visited {
        color: #292929;
    }
    
    .title {
        font-family: 'Playfair Display', serif;
        font-size: 1.5rem;
        text-align: left;
        color: #292929;
        padding-bottom: .5rem;
    }

    
    .description {
        font-family: 'EB Garamond', serif;
        text-align: left;
        font-size: 100%;
        padding-bottom: .75rem;
        color: #292929;
    }
    
    .date {
        font-family: 'Playfair Display', serif;
        text-align: left;
        font-size: 100%;
        color: #292929;
        font-style: italic;
    }

    .arrow {
        transform: scale(.66) translateY(25%);
    }
}


