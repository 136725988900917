@media screen and (max-width: 768px) {
  .profile {
    max-width: 85%;
    margin: auto;
  }
  .name {
    max-width: 85%;
    margin: auto;
  }
  .subhead {
    max-width: 85%;
    margin: auto;
  }
  .body {
    max-width: 85%;
    margin: auto;
  }
}

.profile > img {
  height: 300px;
}

.name {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  text-align: left;
  margin: auto;
  max-width: 60%;
}

.subhead {
  font-family: 'EB Garamond', serif;
  font-size: 175%;
  text-align: left;
  margin: auto;
  max-width: 60%;
}

.body {
  font-family: 'EB Garamond', serif;
  font-size: 125%;
  text-align: left;
  margin: auto;
  max-width: 60%;
}

.image {
  border: 3px solid #292929;
}
